<template>
  <v-container fluid grid-list-xs fill-height class="container">
    <v-layout row wrap class="layout header_bg" fluid>
      <v-flex xs12 class="row_title">
        <v-layout
          row
          style="
            flex-flow: row;
            background-color: GhostWhite;
            padding-top: 10;
            font-weight: bold;
            font-size: 5vw;
          "
          class="grey lighten-5"
        >
          <v-flex xs4 class="text-left ml-0 pl-0 mb-2 mt-1">
            <v-btn icon @click="OnBackToReport()">
              <v-icon color="pink lighten-1" large
                >mdi-arrow-left-bold-circle</v-icon
              >
            </v-btn>
          </v-flex>

          <v-flex
            xs8
            class="text-left mr-1 mt-2 mr-2 pink--text text--darken-1"
          >
            ผลการออกรางวัล</v-flex
          >
        </v-layout>
        <v-divider class="pink lighten-1"></v-divider>
      </v-flex>

      <v-flex xs12>
        <v-layout
          row
          style="flex-flow: row; font-weight: bold"
          class="mt-3 mb-1 py-0"
        >
          <v-flex xs12 class="text-center">
            งวดวันที่ {{ getFullPeriodThai(PeriodDT) }}
          </v-flex>
        </v-layout>

        <v-simple-table class="mt-3" dense>
          <template v-slot:default>
            <!-- <thead>
              <tr>
                <th colspan="2" class="border text-center" width="25%">รางวัลที่ 1</th>
                <th colspan="2" class="border text-center border-right" width="25%">
                  เลขท้าย 2 ตัว
                </th>
              </tr>
            </thead> -->
            <tbody>
              <tr>
                <td colspan="2" class="border text-center" width="25%">
                  รางวัลที่ 1
                </td>
                <td
                  colspan="2"
                  class="border text-center border-right"
                  width="25%"
                >
                  เลขท้าย 2 ตัว
                </td>
              </tr>
              <tr>
                <td colspan="2" class="border text-center" width="25%">
                  {{ FirstPrize }}
                </td>
                <td
                  colspan="2"
                  class="border text-center border-right"
                  width="25%"
                >
                  {{ LastTwo }}
                </td>
              </tr>
              <tr>
                <td style="border-top: 1px solid black" colspan="4"></td>
              </tr>
              <tr>
                <td colspan="2" class="border text-center" width="25%">
                  เลขหน้า 3 ตัว
                </td>
                <td
                  colspan="2"
                  class="border text-center border-right"
                  width="25%"
                >
                  เลขท้าย 3 ตัว
                </td>
              </tr>
              <tr>
                <td class="border border-bottom text-center" width="25%">
                  {{ FourTime1 }}
                </td>
                <td class="border border-bottom text-center" width="25%">
                  {{ FourTime2 }}
                </td>
                <td class="border border-bottom text-center" width="25%">
                  {{ FourTime3 }}
                </td>
                <td
                  class="border border-bottom text-center border-right"
                  width="25%"
                >
                  {{ FourTime4 }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-layout>
  </v-container>

  <!-- <div>ตาราง</div> -->

  <!-- <div>ยอดลอย</div> -->
</template>

<script>
import globalFunctionMixin from "@/mixins/globalFunctionMixin";

// import ReportRow from "@/components/Report/CoverPage/Row";

export default {
  name: "Pages_Main",
  mixins: [globalFunctionMixin],
  components: {
    //component name,
    // ReportRow,
  },
  computed: {
    // // //ดึงข้อมูลจาก vuex มาแสดงในตาราง
    // PriceSum() {
    //   return parseInt(this.$store.getters["page/PageData"].PriceSum);
    // },
  },
  mounted() {
    this.ckLogin();
    window.scrollTo(0, 0);
    //   this.PageNumber = this.$route.query.PageNumber;
    //   this.CusID = this.$route.query.CusID;
    //   // this.updateDataTableHeight();
    //   setTimeout(() => {
    //     this.updateDataTableHeight();
    //   }, 500);
    this.GetData();
  },
  methods: {
    async GetData() {
      let parameter = {
        to: "Report",
        method: "prize",
        PeriodDT: this.PeriodDT,
      };

      await this.$store.dispatch("page/TransferData", parameter).then(
        (response) => {
          this.FirstPrize = response.REPORT_PRIZE.FirstPrize;
          this.LastTwo = response.REPORT_PRIZE.LastTwo;
          this.FourTime1 = response.REPORT_PRIZE.FourTime1;
          this.FourTime2 = response.REPORT_PRIZE.FourTime2;
          this.FourTime3 = response.REPORT_PRIZE.FourTime3;
          this.FourTime4 = response.REPORT_PRIZE.FourTime4;
        },
        (error) => {}
      );
    },

    OnBackToReport() {
      this.$router.push({
        path: "Reports",
      });
    },
  },
  data: () => ({
    HostID: sessionStorage.getItem("HostID"),
    LineID: sessionStorage.getItem("LineID"),
    AgentID: sessionStorage.getItem("AgentID"),
    PeriodDT: sessionStorage.getItem("PeriodDT"),

    FirstPrize: "",
    LastTwo: "",
    FourTime1: "",
    FourTime2: "",
    FourTime3: "",
    FourTime4: "",

    CloseTime: "",
    PeriodStatus: 0,
    PeriodIsOpen: 0,
  }),
};
</script>

<style scoped>
.container {
  background-color: white;
  flex-grow: 1;
  flex-shrink: 0;
}
.layout {
  flex-flow: column;
  padding: 2px;
}
.row_title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
  width: 100%;
  flex: 0 1;
}

.border {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
}
.border-bottom {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
}
.border-right {
  border-right: 1px solid black;
}
</style>
